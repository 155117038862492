<template>
    <div class="nav-bar">
        <img src="../assets/images/logo.svg" alt="" class="logo-img">
        <div class="down-flex">
            <h6>Assistance:</h6>
            <h5>435-772-5040</h5>
        </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'Header',
  
      data: () => ({
        
      }),
    }
  </script>

<style scoped>
.nav-bar {
    height: 56px;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
}
.down-flex {
    display: flex;
    flex-direction: column;
}
h6 {
    color: #000;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}
h5 {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}
.logo-img {
    width: 128px;
}
</style>
  