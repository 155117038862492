import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ZoneA from "../views/ZoneA.vue";
import ZoneB from "../views/ZoneB.vue";
import ZoneC from "../views/ZoneC.vue";
import Brochure from "../views/Brochure.vue";
import Scan from "../views/LicenseScan.vue";
import InfoPage from "../views/InfoPage.vue";
import VueMask from "v-mask";

Vue.use(VueRouter);
Vue.use(VueMask);

const routes = [
  {
    path: "/a",
    name: "zone-a",
    component: ZoneA,
  },
  {
    path: "/b",
    name: "zone-b",
    component: ZoneB,
  },
  {
    path: "/c",
    name: "zone-c",
    component: ZoneC,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/brochure/:location",
    name: "brochure",
    component: Brochure,
  },
  {
    path: "/scan",
    name: "scan",
    component: Scan,
  },
  {
    path: "/info/:zone/:variant",
    name: "info",
    component: InfoPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
