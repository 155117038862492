<template>
  <v-app>
    <Header />
    <div class="content">
      <div class="space-between">
        <div class="group">
          <img v-if="zone == 'A'" src="../assets/images/a-icon.svg" alt="" />
          <img v-if="zone == 'B'" src="../assets/images/b-icon.svg" alt="" />
          <img v-if="zone == 'C'" src="../assets/images/c-icon.svg" alt="" />
          <div class="down-flex">
            <h3>Zone {{ zone }}</h3>
            <h4>{{ text }}</h4>
          </div>
        </div>
        <h3>${{ price }}.35</h3>
      </div>
      <h1>Personal Info</h1>
      <div class="field-cont">
        <!-- <v-text-field
          label="Phone Number"
          outlined
          class="input-box"
          color="#1876BB"
          aria-required="true"
          v-on:change="checkCont()"
          :error="phoneError.error"
          :error-messages="phoneError.message"
          error-color="#96a145"
          v-model="phone_number"
          messages="Use same number on next page"
        >
        </v-text-field> -->
        <v-text-field
          label="License Plate"
          outlined
          class="input-box"
          color="#1876BB"
          aria-required="true"
          v-on:change="checkCont()"
          :error="plateError.error"
          :error-messages="plateError.message"
          error-color="#96a145"
          v-model="license_plate"
          :maxlength="8"
          messages="No spaces or dashes"
        >
        </v-text-field>
        <div class="flex-end">
          <button v-if="zone == 'A'" class="blue-fill" @click="checkErrors()">
            Pay Now
          </button>
          <button v-if="zone == 'B'" class="green-fill" @click="checkErrors()">
            Pay Now
          </button>
          <button v-if="zone == 'C'" class="red-fill" @click="checkErrors()">
            Pay Now
          </button>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import $ from "jquery";
import Header from "@/components/Header.vue";
import DataTable from "@/assets/DataTable.json";
export default {
  name: "Home",

  components: {
    Header,
  },
  data() {
    return {
      selected: null,
      zone: this.$route.params.zone,
      variant_id: this.$route.params.variant,
      dataTable: DataTable,
      text: "",
      price: "",
      license_plate: "",
      phone_number: "",
      cont: false,
      phoneError: {
        error: false,
        message: "",
      },
      plateError: {
        error: false,
        message: "",
      },
      uuid: "",
    };
  },
  created() {
    console.log("hello");
    this.loadShopifyCollection();
    this.zone = this.zone.toUpperCase();
    console.log("dataTable: ", this.dataTable);

    // grab the text
    for (var i in this.dataTable) {
      if (this.dataTable[i].variant_id == this.variant_id) {
        this.text = this.dataTable[i].text;
        this.price = this.dataTable[i].value;
      }
    }
    this.uuid = this.generateUUID();
  },
  methods: {
    selectItem(item) {
      console.log("Selected:", item);
      this.selectedItem = item;
      // Here you can handle further logic upon selection
    },
    payNow() {},
    generateUUID() {
      // Public Domain/MIT
      let d = new Date().getTime(); //Timestamp
      let d2 =
        (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    checkCont() {
      console.log("phone: ", this.phone_number);
      console.log("checking contact");
      if (this.license_plate != "") {
        this.plateError.error = false;
        this.plateError.message = "";
        this.cont = true;
      }
    },
    checkErrors() {
      if (this.cont == false) {
        if (this.license_plate == "") {
          this.plateError.error = true;
          this.plateError.message = "License plate is required";
        } else {
          this.plateError.error = false;
          this.plateError.message = "";
        }
      } else {
        // this.MakeShiftShopify();
        this.SendToJared();
      }
    },
    SendToJared() {
      var _this = this;
      var settings = {
        url: `https://trailwaze.info/aimsparking/lphold.php?license_plate=${this.license_plate}&variant_id=${this.variant_id}&uuid=${this.uuid}`,
        method: "GET",
        timeout: 0,
        headers: {
          PARKSPLUS_AUTH: "13b4ab63-51bc-4ea5-9775-645b96e3bfb7",
        },
      };

      $.ajax(settings).done(function (response) {
        console.log(response);
        _this.MakeShiftShopify();
      });
    },
    MakeShiftShopify() {
      console.log("make shift shopify");
      var client = ShopifyBuy.buildClient({
        domain: "d5406a-46.myshopify.com",
        storefrontAccessToken: "8ae83a9bcde90339da8761d3dfb68a16",
      });

      var variantId = window.btoa(
        `gid://shopify/ProductVariant/${this.variant_id}`
      );

      // Generate a UUID
      const uuid = this.uuid;

      client.checkout
        .create({
          customAttributes: [{ key: "UUID", value: uuid }],
        })
        .then((checkout) => {
          var lineItemsToAdd = [
            {
              variantId: variantId,
              quantity: 1,
            },
          ];
          return client.checkout.addLineItems(checkout.id, lineItemsToAdd);
        })
        .then((checkout) => {
          // Direct the user to the checkout
          window.location.href = checkout.webUrl;
          console.log("shopify url: ", checkout.webUrl);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    loadShopifyCollection: function () {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          //this.ShopifyBuyInit(this.collectionId);
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    loadScript: function () {
      //create html script tag and run after it loads
      var scriptURL =
        "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      //script.onload = this.ShopifyBuyInit(this.collectionId);
    },
  },
};
</script>
<style scoped>
h2 {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 8px;
  line-height: normal;
}
h1 {
  color: #3a3736;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 15px;
}
.content {
  width: 100%;
}
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.image-container img {
  width: 100%;
  height: auto;
}
.img-text {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 16px 20px;
}
p {
  color: #3a3736;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px 20px;
}
.options {
  margin: 15px 20px;
}
h3 {
  color: #3a3736;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h4 {
  color: #3a3736;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.h5 {
  color: #3a3736;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.selections {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.list-item {
  border-radius: 4px;
  border: 1px solid #bebebe;
  background: #fff;
  margin-bottom: 12px;
}
.list {
  margin-top: 10px;
}
.blue-fill {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  border-radius: 4px;
  background: #1876bb;
  padding: 9px 16px;
}
.green-fill {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  border-radius: 4px;
  background: #6d8a3a;
  padding: 9px 16px;
}
.red-fill {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  border-radius: 4px;
  background: #b1473a;
  padding: 9px 16px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.list-item.v-list-item.v-item--active.v-list-item--active.v-list-item--link.theme--light {
  border-radius: 4px;
  border: 2px solid #1876bb;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}
.v-application .primary--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}
.group {
  display: flex;
  align-items: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
}
.down-flex {
  margin-left: 10px;
}
.field-cont {
  margin: 20px 15px;
}
</style>
<style>
.v-radio {
  align-items: flex-start;
}
.v-messages__message {
  color: #e31e06;
}
/* .theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
    opacity: 0.0; 
    border-radius: 4px;
    border: 2px solid #1876BB;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
} */
/* .v-list-item-group .vlist-item--active {
    border-radius: 4px;
    border: 2px solid #1876BB;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
} */
/* .list-item.v-list-item.v-item--active.v-list-item--active.v-list-item--link.theme--light {
    border-radius: 4px;
    border: 2px solid #1876BB;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}
.v-application .primary--text {
    color: #ffffff !important;
    caret-color: #ffffff !important;
} */
</style>
