<template>
  <v-app>
    <Header />
    <div class="content">
      <div class="image-container">
        <img src="../assets/images/zoneC.svg" alt="" />
        <div class="img-text">
          <h2>Street Parking in Springdale, UT</h2>
          <h1>OVERSIZED VEHICLES ARE ALLOWED</h1>
          <h2>
            This includes recreational vehicles, trailers and vehicles over 8
            feet in height
          </h2>
        </div>
      </div>
      <div>
        <p>
          On Street Paid parking and enforecement hours are 6:00AM - 5:00PM
          daily
        </p>
        <div class="options">
          <h3>Vehicle Size</h3>
          <v-radio-group v-model="selectedSize" @change="onRadioChange" column>
            <v-radio value="standard" color="#3A3736">
              <template v-slot:label>
                <div class="h5">
                  <strong class="h4">Standard</strong><br />
                  (Sedan, SUV, Motorcycle, Van, Pickup Truck)
                </div>
              </template>
            </v-radio>
            <v-radio value="oversize" color="#3A3736">
              <template v-slot:label>
                <div class="h5">
                  <strong class="h4">Oversize</strong><br />
                  (Recreational vehicles, trailers, and vehicles over 8 feet in
                  height)
                </div>
              </template>
            </v-radio>
          </v-radio-group>

          <h3>No Refunds</h3>
          <v-list class="list" v-if="selectedSize == 'standard'">
            <v-list-item-group v-model="selected" color="primary" mandatory>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(item)"
                class="list-item"
              >
                <div class="selections">
                  <h3>{{ item.text }}</h3>
                  <h3>{{ item.value }}</h3>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list class="list" v-if="selectedSize == 'oversize'">
            <v-list-item-group v-model="selected" color="primary" mandatory>
              <v-list-item
                v-for="(item, index) in items2"
                :key="index"
                @click="selectItem(item)"
                class="list-item"
              >
                <div class="selections">
                  <h3>{{ item.text }}</h3>
                  <h3>{{ item.value }}</h3>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div class="flex-end" v-if="selectedSize != ''">
            <button class="blue-fill" @click="GoToInfo()">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  name: "Home",

  components: {
    Header,
  },
  data() {
    return {
      selected: null,
      items: [
        { text: "All Day", value: "$12.35", variant_id: "48447484100922" },
        { text: "1 Hour", value: "$1.35", variant_id: "48447484133690" },
        { text: "2 Hours", value: "$2.35", variant_id: "48447484166458" },
      ],
      items2: [
        { text: "All Day", value: "$24.35", variant_id: "48447484199226" },
        { text: "1 Hour", value: "$2.35", variant_id: "48447484231994" },
        { text: "2 Hours", value: "$4.35", variant_id: "48447484264762" },
      ],
      selectedSize: "",
      selectedItem: "",
      zone: "c",
    };
  },
  created() {
    this.loadShopifyCollection();
    this.selectedItem = this.items[0];
  },
  methods: {
    selectItem(item) {
      console.log("Selected:", item);
      this.selectedItem = item;
      // Here you can handle further logic upon selection
    },
    GoToInfo() {
      this.$router.push(`/info/${this.zone}/${this.selectedItem.variant_id}`);
    },
    onRadioChange() {
      if (this.selectedSize == "standard") {
        this.selectedItem = this.items[0];
      } else {
        this.selectedItem = this.items2[0];
      }
    },
    MakeShiftShopify() {
      //if nothing was selected
      console.log("selected item: ", this.selectedItem);
      if (this.selectedItem == "") {
        console.log("Please select an item");
        if (this.selectedSize == "standard") {
          this.selectedItem = this.items[0];
        } else {
          this.selectedItem = this.items2[0];
        }
      }

      console.log("make shift shopify");
      var client = ShopifyBuy.buildClient({
        domain: "d5406a-46.myshopify.com",
        storefrontAccessToken: "8ae83a9bcde90339da8761d3dfb68a16",
      });

      var variantId = window.btoa(
        `gid://shopify/ProductVariant/${this.selectedItem.variant_id}`
      );

      client.checkout
        .create()
        .then((checkout) => {
          var lineItemsToAdd = [
            {
              variantId: variantId,
              quantity: 1,
            },
          ];
          return client.checkout.addLineItems(checkout.id, lineItemsToAdd);
        })
        .then((checkout) => {
          // Direct the user to the checkout
          //testing so im going to comment this out
          window.location.href = checkout.webUrl;
          console.log("shopify url: ", checkout.webUrl);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    loadShopifyCollection: function () {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          //this.ShopifyBuyInit(this.collectionId);
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    loadScript: function () {
      //create html script tag and run after it loads
      var scriptURL =
        "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      //script.onload = this.ShopifyBuyInit(this.collectionId);
    },
  },
};
</script>
<style scoped>
h2 {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 8px;
  line-height: normal;
}
h1 {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 8px;
}
.content {
  width: 100%;
}
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.image-container img {
  width: 100%;
  height: auto;
}
.img-text {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 16px 20px;
}
p {
  color: #3a3736;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px 20px;
}
.options {
  margin: 15px 20px;
}
h3 {
  color: #3a3736;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.h4 {
  color: #3a3736;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.h5 {
  color: #3a3736;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.selections {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.list-item {
  border-radius: 4px;
  border: 1px solid #bebebe;
  background: #fff;
  margin-bottom: 12px;
}
.list {
  margin-top: 10px;
}
.blue-fill {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  border-radius: 4px;
  background: #b1473a;
  padding: 9px 16px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.list-item.v-list-item.v-item--active.v-list-item--active.v-list-item--link.theme--light {
  border-radius: 4px;
  border: 2px solid #b1473a;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  color: #ffffff !important;
  caret-color: #ffffff !important;
}
.v-application .primary--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}
</style>
<style>
.v-radio {
  align-items: flex-start;
}
/* .theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
    opacity: 0.0; 
    border-radius: 4px;
    border: 2px solid #1876BB;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
} */
/* .v-list-item-group .vlist-item--active {
    border-radius: 4px;
    border: 2px solid #1876BB;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
} */
/* .list-item.v-list-item.v-item--active.v-list-item--active.v-list-item--link.theme--light {
    border-radius: 4px;
    border: 2px solid #6D8A3A;
    background-color: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    color: #ffffff !important;
    caret-color: #ffffff !important;
}
.v-application .primary--text {
    color: #ffffff !important;
    caret-color: #ffffff !important;
} */
</style>
