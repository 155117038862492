<template>
    <v-app>
        <Header />
        <div class="content">
            <div class="side-flex">
                <img src="../assets/images/wifi.svg" alt="">
                <p>Limited Wifi access in the park</p>
            </div>
            <p>Save image to your phone or Download the <span class="bolder">Greater Zion App</span></p>
            <div class="button-cont">
                <button class="blue-buttons" @click="redirect('https://play.google.com/store/apps/details?id=edu.dixietech.parksplus.greaterzion')">
                    <img src="../assets/images/android.svg" alt="">
                    ANDROID
                </button>
                <button class="blue-buttons" @click="redirect('https://apps.apple.com/us/app/greater-zion/id1499911624')">
                    <img src="../assets/images/ios.svg" alt="">
                    IOS
                </button>
            </div>
        </div>
        <div class="brochure-cont">
            <img src="../assets/images/brochures/ZionBrochure.jpg" alt="brochure for zion national park" class="brochure">
        </div>
    </v-app>
  </template>
  
  <script>
    
  import Header from '@/components/Header.vue'
    export default {
      name: 'Home',
  
      components: {
        Header
      },
      data() {
        return {selected: null,
            items: [
                { text: 'All Day', value: '$20.00', variant_id: '48447455527226' },
                { text: '1 Hour', value: '$1.00', variant_id: '48447455559994'},
                { text: '2 Hours', value: '$2.00', variant_id: '48447455592762' }
            ],
            selectedSize: 'standard',
            selectedItem: '',
            location: ''
        };
    },
    created() {
        //this.loadShopifyCollection();
        this.location = this.$route.params.location;
    },
    methods: {
        selectItem(item) {
        console.log('Selected:', item);
        this.selectedItem = item;
        // Here you can handle further logic upon selection
        },
        payNow(){

        },
        redirect(url){
            window.open(url, '_blank');
        },
        MakeShiftShopify(){
            if(this.selectedItem == ''){
                this.selectedItem = this.items[0];
            }
                console.log("make shift shopify");
                var client = ShopifyBuy.buildClient({
                    domain: 'd5406a-46.myshopify.com',
                    storefrontAccessToken: '8ae83a9bcde90339da8761d3dfb68a16'
                });

                var variantId = window.btoa(`gid://shopify/ProductVariant/${this.selectedItem.variant_id}`);

                client.checkout.create().then((checkout) => {
                    var lineItemsToAdd = [
                        {
                            variantId: variantId,
                            quantity: 1,
                        },
                    ];
                    return client.checkout.addLineItems(checkout.id, lineItemsToAdd);
                }).then((checkout) => {
                    // Direct the user to the checkout
                    //testing so im going to comment this out
                    window.location.href = checkout.webUrl;
                    console.log("shopify url: ", checkout.webUrl);
                }).catch((error) => {
                    console.error('Error:', error);
                });

                
            },
            loadShopifyCollection: function () {
                if (window.ShopifyBuy) {
                    if (window.ShopifyBuy.UI) {
                    //this.ShopifyBuyInit(this.collectionId);
                    } else {
                    this.loadScript();
                    }
                } else {
                    this.loadScript();
                }
            },
            loadScript: function () {
                //create html script tag and run after it loads
                var scriptURL =
                    "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
                var script = document.createElement("script");
                script.async = true;
                script.src = scriptURL;
                (
                    document.getElementsByTagName("head")[0] ||
                    document.getElementsByTagName("body")[0]
                ).appendChild(script);
                //script.onload = this.ShopifyBuyInit(this.collectionId);
            },
  }
    }
  </script>
<style scoped>
    h2 {
        color: #FFF;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding-bottom: 8px;
        line-height: normal;
    }
    h1 {
        color: #FFF;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        padding-bottom: 8px;
    }
    .content{
        padding: 10px;
    }
    .content > div > img {
        padding-right: 13px;
    }
    .side-flex {
        display: flex;
        align-items: center;
    }
    .image-container {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .image-container img {
        width: 100%;
        height: auto;
    }
    .img-text {
        position: absolute;
        bottom: 6px;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 16px 20px;
    }
    p {
        color: #3A3736;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0px;
    }
    .options {
        margin: 15px 20px;
    }
    h3 {
        color: #3A3736;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .h4 {
        color: #3A3736;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .h5{
        color: #3A3736;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .selections {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .list-item {
        border-radius: 4px;
        border: 1px solid #BEBEBE;
        background: #FFF;
        margin-bottom: 12px;
    }
    .list {
        margin-top: 10px;
    }
    .blue-fill {
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 900;
        line-height: normal;
        border-radius: 4px;
        background: #1876BB;
        padding: 9px 16px;
    }
    .flex-end {
        display: flex;
        justify-content: flex-end;
    }
    .list-item.v-list-item.v-item--active.v-list-item--active.v-list-item--link.theme--light {
        border-radius: 4px;
        border: 2px solid #1876BB;
        background: #FFF;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    }
    .v-application .primary--text {
        color: #ffffff !important;
        caret-color: #ffffff !important;
    }
    .brochure {
        width: 100%;
        height: auto;
    
    }
    .bolder {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .blue-buttons {
        background-color: #1876BB;
        color: #FFF;
        border-radius: 4px;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        padding: 8px 16px;
        align-items: center;
    }
    .button-cont {
        display: flex;
        align-items: center;
        margin-top: 5px;
    }
    .button-cont > button {
        margin-right: 10px;
    }
    .button-cont > button > img {
        margin-right: 10px;
    }
</style>
<style>
.v-radio {
    align-items: flex-start;
}
/* .theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
    opacity: 0.0; 
    border-radius: 4px;
    border: 2px solid #1876BB;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
} */
/* .v-list-item-group .vlist-item--active {
    border-radius: 4px;
    border: 2px solid #1876BB;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
} */
/* .list-item.v-list-item.v-item--active.v-list-item--active.v-list-item--link.theme--light {
    border-radius: 4px;
    border: 2px solid #1876BB;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}
.v-application .primary--text {
    color: #ffffff !important;
    caret-color: #ffffff !important;
} */
</style>
  