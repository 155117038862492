<template>
    <v-app>
        <div id="app-bar" v-show="(PassTab == false) && (scan == true)">
            <div class="slider-bar" style="padding-left: 12px;">
                <!-- <div class="slider inactive" style="z-index: 1;">
                    <img src="../assets/nav-icons/qrcode.svg" alt="">
                    <h5>Passes</h5>
                </div> -->
                <!-- <div class="slider active" style="z-index: 2; position: relative; left: -12px;">
                    <h5>Plates</h5>
                </div> -->
                <h5>Scan License Plate</h5>
            </div>
        </div>
        

        <div class="ScanningLicensePlates" v-show="PassTab == false">
            <div>
                <div id="videoCamera" style="z-index: -1">
                    <video ref="video" autoplay playsinline></video>
                </div>
                <h1 v-if="carFeedback" id="feed">{{feedback}}</h1>
                <!-- <button  v-on:click="doScreenshot()" ref="screenshot" id="screenshotB" v-bind:style="{'background-color': bcolor}">
                    <v-icon v-if="loadingP" x-large>mdi-loading mdi-spin</v-icon>

                    <v-icon v-else x-large id="shutter" >mdi-camera-iris</v-icon>
                    
                </button> -->
                
                <canvas ref="canvas" style="display: none"></canvas>

                <div class="ScanCheckinError" v-show="(NoPlate == true)">
                    <!-- <img src="../assets/nav-icons/x.svg" alt=""> -->
                    <h2 class="ScanHeader">No Pass Found</h2>
                    <h3 class="ScanBody">There is no pass associated with this plate: {{ plate }}</h3>
                </div>
            </div>

            <!-- <div v-show="scan == false">
                <div id="app-bar" >
                    <div style="display:flex;align-items: center;position: absolute;left: 20px;" @click="goToScan()" >
                        <img src="../assets/images/arrow_left.svg" alt="">
                        <h1 style="font-style: normal;font-weight: 400;font-size: 14px;">Scan</h1>
                    </div>
                    <h1>Pass</h1>
                    
                </div>
                <div id="grad-banner">
                    <h4 v-show="parkName != 'All Parks'">{{ parkName }}</h4>
                    <h4 v-show="parkName == 'All Parks'">{{ selectedParkTitle }}</h4>
                    <h2 style="font-size: 14px">{{ prettyDate }}</h2>
                </div>
                <div class="warning-cont" v-if="warning == true">
                    <div class="warning-box">
                        <img src="../assets/images/warning.svg" alt="">
                        <p class="warning-text"><bold class="bold">Warning:</bold> This pass isn't for today! Check the date!</p>
                    </div>
                </div>
                <h3 style="margin:20px 0px">License Plate: {{ pass.license_plate }}</h3>
                <button v-show="pass.checked_in == '0'" class="blue-button" @click="checkIn(pass.order_number)">
                    <img src="../assets/images/white-checked.svg" alt="" style="margin-right: 10px;">
                    Check-In
                </button>
                <button v-show="pass.checked_in == '1'" class="red-button" @click="checkOut(pass.order_number)">
                    <img src="../assets/images/cancel.svg" alt="" style="margin-right: 10px;">
                    Cancel Check-In
                </button>

                <v-card class="item-card" style="margin-top: 35px; box-shadow: none;">
                    <div class="down-flex">
                        <div class="item-flex">
                            <h2 class="title">Pass Type</h2>
                            <h2 id="overflow">{{ pass.passName }}</h2>
                        </div>
                        <v-divider></v-divider>
                        <div class="item-flex">
                            <h2 class="title">Confirmation ID</h2>
                            <h2 class="table-value">{{ pass.order_number }}</h2>
                        </div>
                        <v-divider></v-divider>
                        <div class="item-flex">
                            <h2 class="title">Purchase Date</h2>
                            <h2 class="table-value">{{ prettyDate }}</h2>

                        </div>
                        
                        
                    </div>
                </v-card>
                <div class="log-cont">
                    <h4 style="margin-top: 30px;">This pass was last seen at:</h4>
                    <div v-for="entry in formattedHistory" class="">
                        <div class="entry">
                            <div>
                                <p v-if="entry.Action == '1'" style="font-weight: 700;">Checked-in at:</p>
                                <p v-else style="font-weight: 700;">Checked-out at:</p>
                                <p>{{ entry.FullPark }}</p>
                            </div>
                            <div>
                                <p>{{ entry.FormattedTime }}</p>
                                <p>{{ entry.FormattedDate }}</p>
                            </div>
                            
                        </div>
                        <v-divider></v-divider>
                        

                    </div>
                </div>
            </div> -->


        </div>

        <div grow class="bottom-nav" style="height: 75px;">
            <button  v-on:click="doScreenshot()" ref="screenshot" id="screenshotB2" v-bind:style="{'background-color': bcolor}">
                <v-icon v-if="loadingP" x-large>mdi-loading mdi-spin</v-icon>

                <v-icon v-else x-large id="shutter" >mdi-camera-iris</v-icon>
                
            </button>
            <!-- <div class="button-cont" @click="redirect('events')">
                <p class="nav-text">Check-In</p>
            </div>

            <div class="button-cont" @click="redirect('scan')">
                <p class="nav-text bright">Scan</p>
            </div>

            <div class="button-cont" @click="redirect('pass/setup')">
                <p class="nav-text">Pass Setup</p>
            </div>

            <div class="button-cont" @click="redirect('settings')">
                <p class="nav-text">Settings</p>
            </div> -->
        </div>
        <v-dialog
            v-model="PlateCheck"
            class="check-dialog"
            >
            <v-card class="check-card">
                <div class="flex-between">
                    <div id="header-left">
                        <!-- <img src="../assets/images/icon/pen.svg" alt=""> -->
                        <h1>Verify Plate</h1>
                        <p>Is this your license plate?</p>
                    </div>
                </div>
                <p class="plate">{{ search }}</p>

                <div class="button-group">
                    <button class=blue-text @click="PlateCheck = false; search = ''">Retry</button>
                    <button class="blue-fill" @click="copyToClipboard()">Copy to Clipboard</button>
                </div>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>


// import { QrcodeStream } from 'vue-qrcode-reader'
  export default {
    name: 'Scan',
    components: {
    },
    data() {
      return {
        componentKey: 0,
        scan: true,
        // passName: '',
        checkInSuccess: false,
        checkOutSuccess: false,
        checkInError: false,
        noPassFound: false,
        PassTab: false,

        constraints: {
            video: {
                facingMode: "environment"
            }
        },
        screenshotTaken: false,
        base64Image: "",
        results:"",
        carFeedback: false,
        feedback:"",
        loadingP: false,
        bcolor: "#0096FF",
        noPasses: false,
        plate: '',
        NoPlate: false,
        warning: false,
        pause: true,
        PlateCheck: false,
      }
    },
    created(){

        console.log("going into start stream");
        setTimeout(() => {
            this.startStream();
        }, 500);



    },
    
    methods: {
        redirect(x){
            console.log("redirecting to: ", x)
            this.$router.push(`/${x}`);
        },
        goToScan(){
            this.scan = true;
            location.reload();
        },
        copyToClipboard(){
            navigator.clipboard.writeText(this.search)
            .then(() => {
            console.log('Text successfully copied to clipboard!');
            this.PlateCheck = false;
            })
            .catch(err => {
            console.error('Failed to copy: ', err);
            });
        },
        grabPass(){
            console.log("in grabPass");
            if( this.parkName == 'All Parks') {
                this.parkPasses = this.EveryPass;
            }
            for(var i in this.parkPasses){
                var foundpass = false;
                if(this.parkPasses[i].auth_code == this.Auth_Code){
                    foundpass = true;
                    console.log("pass found");
                    //this.parkPasses[i].checked_in = "1";
                    //localStorage.setItem("parkPasses", JSON.stringify(this.parkPasses));
                    this.pass = this.parkPasses[i];
                    var date = this.pass.date_created
                    var date = moment(date).format("MMM D");
                    this.prettyDate = date;

                    var today = moment().format("MMM D");
                    if(this.parkName != 'Utahraptor State Park' && this.prettyDate != today){
                        this.warning = true;
                    }

                    if (this.parkName == 'Utahraptor State Park'){

                        for (var i in this.RaptorVarIds){
                            console.log("looking for var ids. here is that pass id: ", this.pass.variant_id);
                            if (this.RaptorVarIds[i] == this.pass.variant_id){
                                console.log('found it!');
                                console.log("i: ", i);
                                var end = moment(this.pass.date_created).add(i, 'days').format("MMM D");
                                console.log("end: ", end);
                                this.raptorPrettyDate = `${this.prettyDate} - ${end}`;

                                var rangeStart = moment(this.prettyDate, "MMM D");
                                var rangeEnd = moment(end, "MMM D");

                                // Get today's date
                                var today = moment().startOf('day');

                                // Return true if today's date is within the range
                                if (today.isBetween(rangeStart, rangeEnd, null, '[]')) {
                                    this.warning = false;
                                }
                                else {
                                    this.warning = true;
                                }
                            }
                        }

                    }


                    
                    console.log("here is the pass found: ", this.pass);
                    this.grabPassType();
                    console.log("autoCheckIn: ", this.autoCheckIn);
                    if(this.autoCheckIn == 'yes'){
                        this.checkIn(this.pass.order_number);
                    }
                    else{
                        this.scan = false;
                    }
                    break;
                }
            }
            if(foundpass == false){
                this.noPassFound = true;
                this.timeClose();

            }
        },
        // grabPassType(){
        //     for(var j in this.eventTable){
        //         for( var k in this.eventTable[j].events){
        //             for(var l in this.eventTable[j].events[k].types){
        //                 if(this.eventTable[j].events[k].types[l].variantId == this.pass.variant_id){
        //                     this.pass.passName = this.eventTable[j].events[k].types[l].name;
        //                     this.passName = this.eventTable[j].events[k].types[l].name;
        //                 }
        //             }
        //         }
        //     }
        // },
      passInfo(){
        console.log("passInfo license plate: ", this.LP_Code);
        //should push to the passInfo page
        //this.redirect(`scan/${this.LP_Code}`);
        this.checkInSuccess = false;
        this.checkInError = false;
        this.scan = false;

      },
      checkIn(orderNum){
            console.log("checking in: ", orderNum);
            var _this = this;
            $.getJSON(`https://trailwaze.info/test/parkpass/checkinQRCode.php?order_number=${orderNum}&checkin=yes&park_abbr=${this.LoggedInParkAbbr}`, function (json) {
                console.log("checkin response: ", json);
                if(json.response = 'CHECK IN SUCCESSFUL'){
                    //update parkpasses
                    _this.updatePasses();
                    _this.checkInSuccess = true;
                    this.checkInError = false;

                    //grab the license code from ticket
                    // for(var i in _this.parkPasses){
                    //     if(_this.parkPasses[i].order_number == orderNum){
                    //         _this.LP_Code = _this.parkPasses[i].lp_code;
                    //         break;
                    //     }
                    // }

                    _this.timeClose();
                    return
                }
                else if(json.response = 'ALREADY CHECKED IN, '){
                    // alert that this pass is already checked in
                    this.checkInError = true;
                    _this.timeClose();
                    return
                }
            }).done(()=>{
                console.log("DONE WITH GETJSON in checkin");
                this.checkInError = false;
                return
            }).fail(()=>{
                console.log("FAILED GETJSON in checkin");
                this.checkInError = true;
                _this.timeClose();
                return
            });
            console.log("after get function in checkin");
            
        },
        checkOut(orderNum){
            console.log("checking out: ", orderNum);
            var _this = this;
            $.getJSON(`https://trailwaze.info/test/parkpass/checkinQRCode.php?order_number=${orderNum}&checkin=no&park_abbr=${this.LoggedInParkAbbr}`, function (json) {
                console.log("checkout response: ", json);
                if(json.response = 'CHECK OUT SUCCESSFUL'){
                    //update parkpasses
                    _this.updatePasses();
                    _this.checkOutSuccess = true;
                    _this.timeClose();
                }
            }).done(()=>{
                console.log("DONE WITH GETJSON in checkout");
                this.checkInError = false;
            }).fail(()=>{
                console.log("FAILED GETJSON in checkout");
            });
            console.log("after get function in checkout");
        },
        updatePasses(){
            console.log("updating passes");
            console.log("abbr in pass update: ", this.abbr);
            var _this = this;
            $.getJSON(`https://trailwaze.info/test/parkpass/inventoryQRCode.php?user_key=ymWAfUH1LWNQzrOJyo5V0ml7&park_id=${this.abbr}`, function (json) {
                console.log("new passes: ", json.tickets);
                _this.parkPasses = json.tickets;
                localStorage.setItem("parkPasses", JSON.stringify(json.tickets));
                for(var i in _this.parkPasses){
                    if(_this.parkPasses[i].order_number == _this.pass.order_number){
                        _this.pass = _this.parkPasses[i];
                        // _this.pass.passName = _this.passName;
                        localStorage.setItem("individualPass", JSON.stringify(_this.parkPasses[i]));
                    }
                }
                _this.grabPassType();
            }).done(()=>{
                console.log("DONE WITH GETJSON in pass update");
                this.checkInError = false;
                return
            }).fail(()=>{
                console.log("FAILED GETJSON in pass update");
                this.checkInError = false;
                return
            });
            console.log("after get function in pass update");

            
        },
        closeAlert(){
            console.log("closing alert message... here is the error: ", this.checkInError);
            if(this.checkInError == true){
                console.log("in if statement going to reload component");
                this.checkInError = false;
                this.reloadComponent();
                console.log("i reloaded the component");

            }
            
            this.checkInSuccess = false;
            this.checkOutSuccess = false;
            this.noPassFound = false;
            this.NoPlate = false;
            return
            
        },
        reloadComponent() {
            // this.componentKey += 1;
        },
        timeClose(){
            console.log("what is the error bool: ", this.checkInError);
            setTimeout(() => {
                this.closeAlert();
            }, 5000);
        },
        otherTab: async function() {
            console.log("other tab clicked");
            var bool = await this.stopStream();

            console.log("bool: ", bool);
            if (bool == true) {
                this.$router.push('/scan');
            }
            else {
                console.log("error stopping stream");
            }

        },

        // liscesnes functions
        doScreenshot: function(){
            //create canvas for screenshot
            this.bcolor = "#808080";
            this.loadingP = true;
            this.$refs.canvas.width = this.$refs.video.videoWidth;
            this.$refs.canvas.height = this.$refs.video.videoHeight;
            this.$refs.canvas.getContext('2d').drawImage(this.$refs.video, 0, 0);
            // convert screenshot to base64 image
            // console.log(this.$refs.canvas.toDataURL("image/jpeg").split(';base64,')[1]);
            this.base64Image = this.$refs.canvas.toDataURL("image/jpeg").split(';base64,')[1];
            this.screenshotTaken = true;
            this.checkcar();
        },
        handleStream: function(stream){
            this.$refs.video.srcObject = stream;
        },

        startStream: async function () {
            console.log("start stream");
            try {
                console.log("constraints: ", this.constraints);
                const stream = await navigator.mediaDevices.getUserMedia(this.constraints);
                this.handleStream(stream);
                // alert("constraints: ", this.constraints[0].video);
            } catch (error) {
                alert("Error starting stream: ", error);
            }
        },
        stopStream: function() {
            return new Promise((resolve, reject) => {
                const videoElement = this.$refs.video;
                console.log("video element: ", videoElement);
                const stream = videoElement.srcObject;
                console.log("stream: ", stream);

                if (stream) {
                    // Stop all tracks in the stream
                    stream.getTracks().forEach(track => track.stop());
                    videoElement.srcObject = null;

                    // Resolve the promise with 'true'
                    resolve(true);
                } else {
                    // Reject the promise because there was no stream to stop
                    reject('No stream to stop');
                }
            });
        },
        closeCapture: function(){
            this.showScan = false;
        },
        // showScanB(){
        //   this.search = "";
        //   this.showScan = true;
        //   this.startStream(this.constraints);
        // },
        checkcar: function(){
            var secret_key = "sk_478f69251187e4f5991fc73c";
            var url = "https://api.openalpr.com/v3/recognize_bytes?recognize_vehicle=1&country=us&secret_key=" + secret_key;
            var data = this.base64Image //image base 64 string

            fetch(url, {
                method: 'POST',
                body: data,
            }).then((response) => {
                response.json().then((data)=> {
                    // console.log(data);
                    // console.log(data.vehicles.length);
                    if(data.error == false){

                        if(data.results.length == 0){
                            console.log("no car found")
                            alert("No car found. Please try again.");
                            this.loadingP = false;
                            this.bcolor = "#0096FF";
                        }
                        else if (data.vehicles.length > 1){
                            console.log("Too many cars found")
                            alert("Too many cars in frame. Try again.");
                            this.loadingP = false;
                            this.bcolor = "#0096FF";
                        }
                        else{
                            console.log("heres the plate: ", data.results[0].plate);
                            this.loadingP = false;
                            this.bcolor = "#0096FF";
                            this.search = data.results[0].plate;
                            // ask if it is correct and if they want to copy for clip board
                            this.PlateCheck = true;
                        }
                    }
                    else{
                        console.log("no car found")
                        alert("No car found. Try again.");
                        this.loadingP = false;
                        this.bcolor = "#0096FF";
                    }
                })
            });

        },
        // getPassForPlate(plate){
        //     console.log("passes: ", this.parkPasses);
        //     console.log('everypass: ', this.EveryPass);
        //     var found = false;
        //     // check annual passes first
        //     if (this.parkName == 'All Parks') {
        //         for (var j in this.EveryPass){
        //             if(this.EveryPass[j] != undefined){
        //                 if(this.EveryPass[j].license_plate == plate || this.EveryPass[j].license_plate2 == plate || this.EveryPass[j].license_plate3 == plate){
        //                     console.log("found annual pass");
        //                     found = true;
        //                     this.AnnualScanned = true;
        //                     this.pass = this.EveryPass[j];
        //                     console.log("pass found for plate: ", this.pass);
        //                     // todays date in this format in moment MMM D
        //                     var date = moment().format("MMM D");
        //                     this.prettyDate = date;

        //                     console.log()
        //                     for(var k in this.eventTable){
        //                         for( var b in this.eventTable[k].events){
        //                             for(var l in this.eventTable[k].events[b].types){
        //                                 if(this.eventTable[k].events[b].types[l].variantId == this.pass.variant_id){
        //                                     this.pass.passName = this.eventTable[k].events[b].types[l].name;
        //                                     this.passName = this.eventTable[k].events[b].types[l].name;
        //                                     this.selectedParkTitle = this.eventTable[k].name;
        //                                 }
        //                             }
        //                         }

        //                         if( this.eventTable[k].abbr == "ANNUAL"){
        //                             console.log("found annual park");
        //                             for( var b in this.eventTable[k].events){
        //                                 console.log(this.eventTable[k].events[b].variantIds);
        //                                 console.log(this.pass.variant_id)
        //                                 if(this.eventTable[k].events[b].variantIds.includes(this.pass.variant_id)){
        //                                     console.log("found pass name: ", this.eventTable[k].events[b].name);
        //                                     this.pass.passName = this.eventTable[k].events[b].name;
        //                                     this.passName = this.eventTable[k].events[b].name;
        //                                 }
        //                             }
        //                         }
        //                     }
        //                     console.log("annual pass: ", this.pass);
        //                     this.scan = false;
        //                     this.NoPlate = false;
        //                     console.log("should be on pass page");
        //                     this.getHistory();
        //                 }
        //             }
        //         }
        //     }
        //     for (var j in this.AnnualPasses){
        //         if(this.AnnualPasses[j].license_plate == plate || this.AnnualPasses[j].license_plate2 == plate || this.AnnualPasses[j].license_plate3 == plate){
        //             console.log("found annual pass");
        //             found = true;
        //             this.AnnualScanned = true;
        //             this.pass = this.AnnualPasses[j];
        //             console.log("annual pass: ", this.pass);
        //             // todays date in this format in moment MMM D
        //             var date = moment().format("MMM D");
        //             this.prettyDate = date;

        //             console.log()
        //             for(var k in this.eventTable){
        //                 if( this.eventTable[k].abbr == "ANNUAL"){
        //                     console.log("found annual park");
        //                     for( var b in this.eventTable[k].events){
        //                         console.log(this.eventTable[k].events[b].variantIds);
        //                         console.log(this.pass.variant_id)
        //                         if(this.eventTable[k].events[b].variantIds.includes(this.pass.variant_id)){
        //                             console.log("found pass name: ", this.eventTable[k].events[b].name);
        //                             this.pass.passName = this.eventTable[k].events[b].name;
        //                             this.passName = this.eventTable[k].events[b].name;
        //                         }
        //                     }
        //                 }
        //             }
        //             console.log("annual pass: ", this.pass);
        //             this.scan = false;
        //             this.NoPlate = false;
        //             console.log("should be on pass page");
        //             this.getHistory();
        //         }
        //     }
        //     // check all passes
        //     if (this.AnnualScanned == false){
        //         for(var i in this.parkPasses){
        //             //console.log(this.parkPasses[i]);
        //             if(this.parkPasses[i].license_plate == plate){
        //                 found = true;
        //                 this.pass = this.parkPasses[i];
        //                 var date = this.pass.date_created
        //                 var date = moment(date).format("MMM D");
        //                 this.prettyDate = date;


        //                 var today = moment().format("MMM D");
        //                 if(this.parkName != 'Utahraptor State Park' && this.prettyDate != today){
        //                     console.log("not today: ", this.prettyDate, today);
        //                     this.warning = true;
        //                 }

        //                 if (this.parkName == 'Utahraptor State Park'){
        //                     console.log("in utahraptor");
        //                     for (var i in this.RaptorVarIds){
        //                         console.log("looking for var ids. here is that pass id: ", this.pass.variant_id);
        //                         if (this.RaptorVarIds[i] == this.pass.variant_id){
        //                             console.log('found it!');
        //                             console.log("i: ", i);
        //                             var end = moment(this.pass.date_created).add(i, 'days').format("MMM D");
        //                             console.log("end: ", end);
        //                             this.raptorPrettyDate = `${this.prettyDate} - ${end}`;

        //                             var rangeStart = moment(this.prettyDate, "MMM D");
        //                             var rangeEnd = moment(end, "MMM D");

        //                             // Get today's date
        //                             var today = moment().startOf('day');

        //                             // Return true if today's date is within the range
        //                             if (today.isBetween(rangeStart, rangeEnd, null, '[]')) {
        //                                 this.warning = false;
        //                             }
        //                             else {
        //                                 this.warning = true;
        //                             }
        //                         }
        //                     }

        //                 }



        //                 this.scan = false;
        //                 this.NoPlate = false;
        //                 console.log("should be on pass page");
        //             }
        //             if(found == false){
        //                 //no pass found associated with plate
        //                 this.plate = plate;
        //                 this.NoPlate = true;
        //                 this.timeClose();
        //             }
        //         }
        //     }
        // },
        getHistory(){
            var _this = this;
            $.getJSON(`https://trailwaze.info/test/parkpass/checkinQRCode.php?order_number=${this.pass.order_number}&action=status`, function (json) {
                console.log("history response: ", json);
                if(json.response = 'SUCCESS'){
                    var history = json.data.check_in_activity;
                    console.log("history: ", history);
                    _this.history = history;
                    _this.FormatHistory()
                }
            }).done(()=>{
                console.log("DONE WITH GETJSON in history");
            }).fail(()=>{
                console.log("FAILED GETJSON in history");
            });
            console.log("after get function in history");
        },
    //     FormatHistory() {
    //         for( var i in this.history){
    //             var hist = {};
    //             const parts = this.history[i].split('-');
    //             const park = parts[0];
    //             const year = parts[1];
    //             const month = parts[2];
    //             const day = parts[3];
    //             const hours = parseInt(parts[4], 10);
    //             const minutes = parts[5];
    //             const action = parts[7]

    //             const date = new Date(year, month - 1, day, hours, minutes);
    //             const formattedDate = `${month}/${day}/${year.substring(2)}`;
    //             const ampm = hours >= 12 ? 'pm' : 'am';
    //             const formattedHours = hours % 12 || 12; // Convert 24h to 12h format
    //             const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

    //             for (var j in this.eventTable){
    //                 if(this.eventTable[j].abbr == park){
    //                     var fullPark = this.eventTable[j].name;
    //                 }
    //             }

    //             // create a object for the history
    //             hist = {
    //                 Park: park,
    //                 Year: year,
    //                 Month: month,
    //                 Day: day,
    //                 Hours: hours,
    //                 Minutes: minutes,
    //                 Action: action,
    //                 FormattedTime: formattedTime,
    //                 FormattedDate: formattedDate,
    //                 DateTime: date,
    //                 FullPark: fullPark
    //             }
    //             this.formattedHistory.push(hist);
    //         }
    //         this.formattedHistory.sort((a, b) => b.DateTime - a.DateTime);
    //         console.log("Formatted HIstory: ", this.formattedHistory);

    //     },
    }
    
  }
</script>

<style scoped>
.warning-box{
    background: rgba(236, 129, 131, 0.5);
    border: 1px solid #E02D30;
    border-radius: 8px;
    display: flex;
    width: 90vw;
    padding: 10px;
    margin-top: 10px;
    align-items: flex-start;
}
.warning-box img{
    margin: 0px 10px 0px 0px;
    /* padding-top: 5px; */
}
.warning-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5A1213;
}
h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #3C3438;

}
.bold{
    font-weight: 700;
}
.warning-cont{
    display: flex;
    justify-content: center;
}
.qrcode-stream-camera{
    width: 100vw !important;
    height: 100vh !important;
}
.qrcode-stream-overlay{
    width: 100vw !important;
    height: 100vh !important;
}
.qrcode-stream-wrapper{
    width: 100vw !important;
    height: 100vh !important;
}
.bottom-nav{
    position: sticky;
    bottom: 0;
    background-color: #3C3438;
    color: #3C3438;
    display: flex;
    justify-content: space-around;
    position: fixed;
    padding: 15px 0px;
    width: 100%;
    align-items: center;
}
.grey{
    color: #3C3438;
}
.blue{
    color: #0069B3 !important;
}
.icon{
    height: 24px;
    width: 24px;
}

#app-bar{
    background-color: #3A3438;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 18px;
}
h1{
    color: #3A3736;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
#grad-banner{
    width: 100%;
    height: 72px;
    /* background-image: url(../assets/images/grad-banner.svg); */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.button-cont > p{
    font-family: "Inter";
    font-size: 12px;
    margin: 3px;
    color: #979797;
}
.bright{
    color: white !important;
}
h2{
    font-family: "InterLight";
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
}
.event{
    font-family: "Inter";
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 48px;
}
h3{
    font-family: "Inter";
    font-size: 20px;
    margin: 15px 0px;
    line-height: 32px;
    font-style: normal;
    font-weight: 500;
    color: #3C3438;
    text-align: center;
}
.v-list{
    padding: 0px;
}

.grey{
    color: #3C3438;
}
.blue{
    color: #0069B3 !important;
}
.button-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


#grad-banner{
    width: 100%;
    height: 72px;
    /* background-image: url(../assets/images/grad-banner.svg); */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.blue-button{
    background-color: #0096FF;
    color: white;
    border-radius: 25px;
    width: 90%;
    margin-left: 5%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.red-button{
    background-color: #E02D30;
    color: white;
    border-radius: 25px;
    width: 90%;
    margin-left: 5%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
p{
    color: #3A3736;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.detail-spacing{
    margin: 14px 0px 14px 18px;
}
.alert-head{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.alert-body{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8C8C8C;
    width: 190px;
}
.alert-icon{
    width: 24px;
    height: 24px;
    margin: 0px 10px;
}
.alert-close{
    width: 16px;
    height: 16px;
    margin: 0px 10px;
    position: absolute;
    right: 4%;
}
.alert-container{
    display: flex;
}
.alert-text{
    display: flex;
    flex-direction: column;
}
.alert{
    position: fixed;
    top: 68px;
    width: 90vw;
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    align-items: center;
}
.alert-flex{
    display: flex;
    justify-content: center;
}

.slider{
    width: 150px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    
}
h5{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px; 
    color: #ffffff;
    padding: 8px;
}
.active{
    background: linear-gradient(269.4deg, #DC3857 0.5%, #FDB003 99.48%);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.inactive{
    background-color: #8C8C8C;
}
.slider-bar{
    display: flex;
}

/* license css */
#camera{
    position: absolute;
    left: 88vw;
}
#videoCamera{
    width: 100vw;
    height: calc(100vh - 112px);
    overflow: hidden;
    display: flex;
    justify-content: center;
}
video{
    background: rgba(0,0,0,0.2);
    height: calc(100vh - 112px);
    /* max-width: 100vw; */
}
#screenshotB{
    width: 65px;
    height: 65px;
    border-radius: 90px;
    border: none;
    background: linear-gradient(269.4deg, #DC3857 0.5%, #FDB003 99.48%);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    /* position: fixed; */
    position: absolute;
    bottom: 82px;
    left: 42%;
}
#screenshotB2{
    width: 65px;
    height: 65px;
    border-radius: 90px;
    border: none;
    background: linear-gradient(269.4deg, #DC3857 0.5%, #FDB003 99.48%);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    /* margin-bottom: 20px; */
    /* position: fixed; */
    /* position: absolute;
    bottom: 82px;
    left: 42%; */
}
#SSicon{
    width: 42px;
    height: 42px;
}

#CapDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}
#feed{
    text-align: center;
    margin-bottom: 15px;
}
#shutter{
    color: white;
}
.ScanCheckin{
    width: 90%;
    margin-left: 5%;
    background-color: #0096FF;
    position: absolute;
    top: 75px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ScanCheckinError{
    width: 90%;
    margin-left: 5%;
    background-color: #E02D30;
    position: absolute;
    top: 75px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ScanCheckin > img{
    margin: 30px 0px;
}
.ScanCheckinError > img{
    margin: 30px 0px;
}
.ScanHeader{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}
.ScanBody{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin: 12px 0px 30px;
}

.down-flex{
    display: flex;
    flex-direction: column;
}
.item-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.item-card{
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #B1B1B1;
    border-radius: 8px;
    width: 90%;
    margin-left: 5%;
}
#overflow{
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
    text-align: right;
}
.grad-back{
    background: linear-gradient(266.07deg, rgba(245, 177, 154, 0.5) -6.16%, rgba(254, 217, 128, 0.5) 103.89%);
    border-radius: 8px 8px 0px 0px;
}
.v-divider{
    margin: 0px 12px;
}
.bottom-flex{
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
}
.item-flex > h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3C3438;
    padding: 12px;
    width: 150px;
    text-align: start;
}
.overflow{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3C3438;
    padding: 12px;
}
.table-value{
    text-align: end !important;
}
.log-cont {
    margin-top: 20px;
    margin-bottom: 50px;
}
.log-cont > h4 {
    font-weight: 700;
}
.entry {
    display: flex;
    margin: 20px 30px;
    align-items: flex-end;
    justify-content: space-between;
}
.log1 {
    width: 50%;
}
.log2 {
    width: 25%;
    text-align: right;
}
.log3 {
    width: 25%;
    text-align: right;
}
.entry > p {
    font-size: 14px;
}
.entry > div > p {
    font-size: 14px;
}
.check-card {
    padding: 12px;
}
.blue-text {
    color: #1876bb;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    margin-right: 10px;
}
.blue-fill {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
    background: #1876bb;
    padding: 9px 16px;
}
.button-group {
    display: flex;
    justify-content: flex-end;
}
.plate {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
}
</style>
