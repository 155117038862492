import { VApp } from 'vuetify/lib/components/VApp';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('Header'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("../assets/images/zoneB.svg"),"alt":""}}),_c('div',{staticClass:"img-text"},[_c('h2',[_vm._v("Street Parking in Springdale, UT")]),_c('h1',[_vm._v("NO OVERSIZED VEHICLES ALLOWED")]),_c('h2',[_vm._v(" NO recreational vehicles, trailers and vehicles over 8 feet in height ")]),_c('h2',[_vm._v("Oversize vehicles need to park in Zone C")])])]),_c('div',[_c('p',[_vm._v(" On Street Paid parking and enforecement hours are 6:00AM - 5:00PM daily ")]),_c('div',{staticClass:"options"},[_c('h3',[_vm._v("Vehicle Size")]),_c(VRadioGroup,{attrs:{"column":""},model:{value:(_vm.selectedSize),callback:function ($$v) {_vm.selectedSize=$$v},expression:"selectedSize"}},[_c(VRadio,{attrs:{"value":"standard","color":"#3A3736"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"h5"},[_c('strong',{staticClass:"h4"},[_vm._v("Standard")]),_c('br'),_vm._v(" (Sedan, SUV, Motorcycle, Pickup Truck, Van Under 8 Feet in Height) ")])]},proxy:true}])})],1),_c('h3',[_vm._v("No Refunds")]),_c(VList,{staticClass:"list"},[_c(VListItemGroup,{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.items),function(item,index){return _c(VListItem,{key:index,staticClass:"list-item",on:{"click":function($event){return _vm.selectItem(item)}}},[_c('div',{staticClass:"selections"},[_c('h3',[_vm._v(_vm._s(item.text))]),_c('h3',[_vm._v(_vm._s(item.value))])])])}),1)],1),_c('div',{staticClass:"flex-end"},[_c('button',{staticClass:"blue-fill",on:{"click":function($event){return _vm.GoToInfo()}}},[_vm._v("Continue")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }